<template>
  <div>
    <b-card class="mb-4">
      <form ref="anyName" class="filtros-auditoria res-inputs">
        <div class="p-1 w-25" v-if="temFiltroCedente">
          <input-select-tag
          unico
          label="CPF ou CNPJ do Cedente"
          :options="cedenteOptions"
          v-model="form.cpfCnpjCedente"
          removerLabelAdicionarRemover
          />
        </div>
        <div class="p-1 flex-fill" v-if="temFiltroCedente">
          <input-text
            label="Empresa"
            v-model="form.nomeFantasia"
          />
          </div>
        <div class="p-1 flex-fill data-filtro">
          <input-date
            id="de"
            :label="$t('AUDITORIA.DE')"
            v-model="form.DataVencimentoDe"
          >
          </input-date>
        </div>
        <div class="p-1 flex-fill data-filtro">
          <input-date
            id="ate"
            :label="$t('AUDITORIA.ATE')"
            v-model="form.DataVencimentoAte"
          />
        </div>

        <div class="p-1 flex-fill documento-sacado">
          <input-select
            :options="statusTitulo"
            :label="$t('SACADO.STATUS')"
            v-model="form.Status"
          />
        </div>
      </form>
      <div class="row">
        <div class="col-12 col-md-6" v-if="temFiltroCedente">
          
        </div>
      </div>
      <div class="col-12 mt-2 d-flex align-items-center justify-content-end">
        <b-button variant="secondary" @click="limparFiltro">
          {{ $t("GERAL.LIMPAR") }}
        </b-button>
        <b-button variant="primary" @click="listarPagamentos()" class="ml-2">
          {{ $t("GERAL.PESQUISAR") }}
        </b-button>
      </div>
    </b-card>
    <div class="">
      <b-card class="mb-4">
        <b-table
          :no-border-collapse="true"
          hover
          head-variant="light"
          :items="items"
          :fields="colunas"
          class="table-scroll"
          @row-dblclicked="onClickRow"
          @sort-changed="sortingChanged"
          :responsive="temFiltroCedente"
        >
          <template #cell(numero)="data">
            <div class="numero-titulo">
              {{ data.index + 1 }}
            </div>
          </template>
          <template #cell(tituloNumeros)="data">
            <div
              class="titulos"
              v-b-tooltip.top
              v-clipboard:copy="data.item.tituloNumeros"
              :title="data.item.tituloNumeros"
            >
              {{ data.item.tituloNumeros }}
            </div>
          </template>
          <template #cell(dados)="data">
            <div class="icones-titulos">
              <b-button
                @click="visualizar(data.item)"
                variant="info"
                v-b-tooltip.hover
                title="Visualizar informações de pagamento"
              >
                <div class="d-flex align-items-center">
                  <i class="fas fa-dollar-sign mr-2"></i>
                  <small>Visualizar dados bancários</small>
                </div>
              </b-button>
            </div>
          </template>
          <template #cell(acoes)="data">
            <div class="icones-titulos">
              <b-button
                @click="removerProgramacao(data.item)"
                :disabled="!getClassButton(data.item)"
                variant="outline-secondary"
                class="mr-2"
                v-b-tooltip.hover
                title="Remover"
              >
                <div>
                  <i class="fas fa-trash"></i>
                </div>
              </b-button>

              <upload-comprovantes :registro="data.item" @atualizar="() => {listarPagamentos(paginaAtual, porPagina);}"/>

              <b-button
                @click="baixarExcel(data.item)"
                variant="outline-secondary"
                class="ml-2"
                v-b-tooltip.hover
                title="Baixar Excel"
              >
                <div>
                  <i class="fas fa-file-excel"></i>
                </div>
              </b-button>

              <b-button
                @click="baixarPDF(data.item)"
                variant="outline-secondary"
                class="ml-2"
                v-b-tooltip.hover
                title="Baixar PDF"
              >
                <div>
                  <i class="fas fa-file-pdf"></i>
                </div>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
    <div class="header-table-operacoes">
      <b-form-group
        label="Por Pagina"
        label-for="per-page-select"
        label-class="fw-medium"
        class="por-pagina"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="setPorPagina"
        ></b-form-select>
      </b-form-group>
      <b-pagination
        @change="setPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
    </div>

    <valorem-modal
      ref="modal-banco"
      :titulo-modal="$t(`TITULOS.INFORMACAO_BANCO`)"
      somente-ok
      titulo-ok="Fechar"
      @ok="fecharModal"
    >
      <div v-if="!infoBanco" class="sem-info">
        {{ $t(`TITULOS.SEM_INFO_BANCO`) }}
      </div>
      <div v-else class="res-input">
        <input-text
          hideFeedback
          disabled
          :label="$t(`TITULOS.INFO_BANCARIA`)"
          class="form-editavel campo-info"
          v-model="this.infoBanco.informacaoBancaria"
        />
        <input-text
          hideFeedback
          disabled
          :label="$t(`TITULOS.CONTA`)"
          class="form-editavel campo-conta"
          v-model="this.infoBanco.conta"
        />
        <input-text
          hideFeedback
          disabled
          :label="$t(`TITULOS.AGENCIA`)"
          class="form-editavel campo-agencia"
          v-model="this.infoBanco.agencia"
        />
        <input-text
          hideFeedback
          disabled
          :label="$t(`TITULOS.CNPJ`)"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          class="form-editavel"
          v-model="this.infoBanco.cnpj"
        />
        <input-text
          hideFeedback
          disabled
          :label="$t(`TITULOS.PIX`)"
          class="form-editavel"
          v-model="this.infoBanco.pix"
        />
      </div>
    </valorem-modal>
  </div>
</template>

<script>
import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputSelectTag from "@/components/inputs/InputSelectTag";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import ProgramacaoService from "@/common/services/programacao/programacao.service";
import { InputDate } from "@/components/inputs";
import { Excel } from "@/common/utils/excel.js";
import moment from "moment";
import UsuarioService from "@/common/services/usuario/usuario.service";
import UploadComprovantes from '@/components/titulos/UploadComprovantes.vue';
export default {
  name: "TitulosProgramado",
  components: {
    InputSelect,
    InputText,
    InputDate,
    InputSelectTag,
    UploadComprovantes
  },
  data() {
    return {
      cedenteOptions: [],
      form: {
        campoOrdenacao: "",
        ascendente: true,
      },
      statusTitulo: [
        {
          value: "Programado",
          text: "Programado",
        },
        {
          value: "Baixado",
          text: "Baixado",
        },
        {
          value: "Cancelado",
          text: "Cancelado",
        },
      ],
      
      formProgramacao: {
        arquivos: [],
      },
      infoBanco: undefined,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("GERAL.PAGINACAO") },
      ],
      valorTotal: 0,
      valorTotalAtualizado: 0,
      tempoAcesso: "",
      validField: [],

      porPagina : 0,
      paginaAtual : 0
    };
  },
  computed: {
    temFiltroCedente() {
      return helpers.validarAcesso("FiltroTodosCedentesProgramacao").visualizar;
    },
    colunas(){
      if(!this.temFiltroCedente)
        return [
          {
            key: "numero",
            label: this.$t("TITULOS.NUMERO"),
            sortable: true,
          },
          {
            key: "dataPagamento",
            label: this.$t("CAMPOS_TABELA_TITULOS.DATA"),
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
            },
            sortable: true,
          },
          {
            key: "tituloNumeros",
            label: this.$t("TITULOS.TITULOS_ENVOLVIDOS"),
          },
          {
            key: "nomeFantasia",
            label: this.$t("SACADO.EMPRESA"),
            sortable: true,
          },
          {
            key: "valorTotalAtualizado",
            label: this.$t("SACADO.VALOR_TOTAL"),
            sortable: true,
            formatter: (value) => {
              if (value) return helpers.formatarValor(value);
            },
          },
          {
            key: "status",
            label: this.$t("SACADO.STATUS"),
            sortable: true,
          },
          {
            key: "dados",
            label: "Dados bancários",
          },
          {
            key: "acoes",
            label: this.$t("GERAL.ACOES"),
          },
        ];

        return [
          {
            key: "numero",
            label: this.$t("TITULOS.NUMERO"),
            sortable: true,
          },
          {
            key: "dataPagamento",
            label: this.$t("CAMPOS_TABELA_TITULOS.DATA"),
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
            },
            sortable: true,
          },
          {
            key: "tituloNumeros",
            label: this.$t("TITULOS.TITULOS_ENVOLVIDOS"),
          },
          {
            key: "nomeCedente",
            label: "CPF ou CNPJ do Cedente",
          },
          {
            key: "nomeFantasia",
            label: this.$t("SACADO.EMPRESA"),
            sortable: true,
          },
          {
            key: "valorTotalAtualizado",
            label: this.$t("SACADO.VALOR_TOTAL"),
            sortable: true,
            formatter: (value) => {
              if (value) return helpers.formatarValor(value);
            },
          },
          {
            key: "status",
            label: this.$t("SACADO.STATUS"),
            sortable: true,
          },
          {
            key: "dados",
            label: "Dados bancários",
          },
          {
            key: "acoes",
            label: this.$t("GERAL.ACOES"),
          },
        ];
      
    }
  },
  mounted() {
    let data = moment();

    this.form.DataVencimentoDe = data.format("YYYY-MM-DD");
    this.form.DataVencimentoAte = data.add("1", "days").format("YYYY-MM-DD");
    this.listarPagamentos();

    if (this.temFiltroCedente) {
      this.getUsuarios();
    }
  },
  methods: {
    getUsuarios() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };
      UsuarioService.listar(params)
        .then((result) => {
          this.cedenteOptions = result.data.data.itens.map((n) => ({
            value: n.cnpj,
            text: n.nomeEmpresa,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    baixarPDF: function (item) {
      this.$store.dispatch(START_LOADING);
      ProgramacaoService.baixarPDFTitulo(item.id)
        .then((res) => {
          var file = res.data.data.file;
          var name = res.data.data.fileName;
          const base64 = `data:application/pdf;base64,${file.fileContents}`;
          const link = document.createElement("a");
          link.href = base64;
          link.download = name;
          link.click();
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    baixarExcel: function (item) {
      this.$store.dispatch(START_LOADING);
      ProgramacaoService.baixarExcel(item.id)
        .then((res) => {
          let htmlString = res.data.data.html;
          let doc = new DOMParser().parseFromString(htmlString, "text/html");
          const ex = new Excel();

          for (let sheet of doc.getElementsByClassName("sheet")) {
            ex.adicionarSheet(sheet, sheet.getAttribute("nome-sheet"));
          }

          ex.baixarExcel("Titulos.xlsx");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    sortingChanged(context) {
      this.items = [];
      this.form.campoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy);
      this.form.ascendente = !context.sortDesc;
      this.listarPagamentos();
    },
    onClickRow(item) {
      console.log(item);
      this.buscarBanco(item);
    },
    visualizar(item) {
      console.log(item);
      this.buscarBanco(item);
    },
    limparFiltro() {
      this.form = {
        campoOrdenacao: "DataPagamento",
        ascendente: true,
      };
      this.listarPagamentos();
    },
    setPage(e) {
      this.listarPagamentos(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listarPagamentos(undefined, e);
      this.perPage = e;
    },
    listarPagamentos(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);
      if (this.form && this.form.Status == null) {
        delete this.form.Status;
      }

      if (!this.temFiltroCedente)
        this.form.cpfCnpjCedente = helpers.getCnpjFromClaim();

      ProgramacaoService.buscarProgramacao({
        ...this.form,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          this.porPagina = porPagina;
          this.paginaAtual = numeroPagina;
          this.items = result.data.data.itens;
          this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.$refs["modal-banco"].fecharModal();
    },
    buscarBanco(item) {
      this.$store.dispatch(START_LOADING);
      ProgramacaoService.buscarBanco({
        codigoFilial: item.codigoFilial,
        codigoEmpresa: item.codigoEmpresa,
      })
        .then((result) => {
          this.infoBanco = result.data.data;
          this.$refs["modal-banco"].abrirModal();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getClassButton(item) {
      return item.status === "Programado";
    },
    removerProgramacao(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          ProgramacaoService.removerProgramacao(item.id)
            .then(() => {
              this.listarPagamentos();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.div-card-titulos {
  display: flex;
  width: 100%;
}

.card-titulos {
  margin: 5px;
}

.card-tabela-titulos {
  width: 80%;
}

.card-conta {
  width: 30%;
  height: 540px;
}

.status-titulo {
  width: 150px;
}

.icone-titulo {
  color: #636970;
}

.numero-titulo {
  width: 60px;
}

.titulos {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botao-icone {
  display: flex;
  align-items: center;
  justify-self: center;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.botao-icone:hover {
  transform: scale(1.2);
}

.icones-titulos {
  display: flex;
  align-items: center;
}

.botao-icone-disabled {
  @extend .botao-icone;
  opacity: 0.6;
  pointer-events: none;
}

.sem-info {
  text-align: center;
  font-weight: 600;
  color: #b1b4b8;
  width: 70%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.campo-info {
  margin-top: 10px;
}
.botao-listar {
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-select-tag
          v-if="temFiltroCedente"
          unico
          class="col-12 col-md-9 mb-1"
          label="CPF ou CNPJ do Cedente"
          :options="cedenteOptions"
          v-model="form.cpfCnpjCedente"
          removerLabelAdicionarRemover
        />
      </div>
      <div class="row">
        <div class="col-12 col-md-6 pl-0">
          <div class="row">
            <input-date
              class="col-12 col-md-6 mb-1"
              :label="$t('AUDITORIA.DE')"
              v-model="form.DataVencimentoDe"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <b-badge variant="info" class="mr-2"
                    >Data de vencimento</b-badge
                  >
                  <span>De:</span>
                </div>
              </template>
            </input-date>
            <input-date
              class="col-12 col-md-6 mb-1"
              :label="$t('AUDITORIA.ATE')"
              v-model="form.DataVencimentoAte"
            />
          </div>
        </div>

        <input-select-tag
          unico
          removerLabelAdicionarRemover
          class="col-12 col-md-3"
          label="Rótulo"
          :options="rotulosOptions"
          v-model="form.rotuloCodigo"
        />

        <div class="col-12 col-md-1">
          <div class="mb-3">{{ $t("SACADO.VISUALIZAR_C99") }}</div>
          <InputCheckbox
            :text="$t(`TITULOS.VISUALIZAR`)"
            @onChange="(e) => (form.C99 = !form.C99)"
            :value="form.C99"
          />
        </div>

        <input-select-tag
          v-if="(sacadoOptions || []).length > 0"
          class="col-12 col-md-6 mb-1"
          :label="$t(`SACADO.SACADO`)"
          :options="sacadoOptions"
          v-model="form.sacadosValue"
          removerLabelAdicionarRemover
        />
        <input-text
          v-if="(sacadoOptions || []).length > 0"
          class="col-12 col-md mb-1"
          ref="Titulo"
          label="Título"
          v-model="form.Titulo"
        />
      </div>
      <div class="row">
        <div
          class="col-12 col-md d-flex align-items-center justify-content-end"
        >
          <b-button variant="secondary" @click="limparFiltro">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            @click="
              () => {
                if (!validarFiltro()) return;
                listarTitulos();
              }
            "
          >
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>
        </div>
      </div>
    </b-card>

    <b-alert
      variant="warning"
      :show="QuantidadeTotalRetonadoQprof != QuantidadeTotalQProf"
    >
      A consulta retornou
      <span class="font-weight-bold"
        >{{ QuantidadeTotalRetonadoQprof }} titulos</span
      >
      de um total de
      <span class="font-weight-bold">{{ QuantidadeTotalQProf }} titulos</span>.
      Para visualizar outros titulos, utilize outro intervalo de data.
    </b-alert>
    <div class="d-flex">
      <div class="flex-fill">
        <b-card>
          <div class="div-tabela">
            <b-table
              class="tabela-valorem"
              head-variant="light"
              hover
              responsive
              :items="items"
              :fields="tabelaTitulos.colunas"
              @sort-changed="sortingChanged"
              :no-local-sorting="true"
            >
              <template #head(arquivo)>
                <div>
                  <InputCheckbox
                    @onChange="() => selecionarTodos()"
                    :value="todosSelecionados"
                  />
                </div>
              </template>
              <template style="width: 50px" v-slot:cell(flagVencido)="item">
                <div
                  :class="`badge-table ${
                    item.item.situacao == 'Em Atraso'
                      ? 'Vencido'
                      : item.item.situacao == 'Baixado'
                      ? 'Baixado'
                      : 'Aberto'
                  }`"
                >
                  {{ item.item.situacao }}
                </div>
              </template>
              <template style="width: 50px" v-slot:cell(status)="item">
                <div :class="`badge-table ${item.item.status}`">
                  {{ $t(`STATUS.${item.item.status.toUpperCase()}`) }}
                </div>
              </template>
              <template style="width: 50px" v-slot:cell(arquivo)="item">
                <div class="flex-pos">
                  <InputCheckbox
                    @onChange="() => selecionarTitulos(item.index)"
                    :value="item.item.active ? true : false"
                  />
                  <feather
                    v-if="item.item.flagTemBoleto"
                    type="file"
                    class="feather-sm"
                  ></feather>
                </div>
              </template>
            </b-table>
          </div>
          <hr />
          <div class="d-flex align-items-center justify-content-end h5">
            <span class="mr-2 font-weight-light"
              >{{ $t(`SACADO.VALOR_SELECIONADO`) }}:</span
            >
            <span class="text-info mr-3">{{
              this.valorTotalSelecionados
            }}</span>
            <span class="mr-2 font-weight-light"
              >{{ $t(`SACADO.VALOR_TOTAL`) }}:</span
            >
            <span class="text-success">{{ this.valorTotalAtualizado }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <span>Por pagina</span>
              <input-select
                no-label
                :options="pageOptions"
                v-model="tabelaTitulos.porPagina"
                class="paginas-tabela-valorem ml-2"
                @input="setPorPagina"
              ></input-select>
            </div>
            <b-pagination
              v-model="tabelaTitulos.paginaAtual"
              :total-rows="tabelaTitulos.quantidadeItens"
              :per-page="tabelaTitulos.porPagina"
              hide-goto-end-buttons
              @change="mudarPaginaTitulos"
            ></b-pagination>
          </div>
        </b-card>
        <!-- <div class="header-table-operacoes">
          <b-form-group
            label="Por Pagina"
            label-for="per-page-select"
            label-class="fw-medium"
            class="por-pagina"
          >
            <b-form-select
              id="per-page-select"
              v-model="tabelaTitulos.porPagina"
              :options="pageOptions"
              @change="setPorPagina"
            >
            </b-form-select>
          </b-form-group>
          <b-pagination
            v-model="tabelaTitulos.paginaAtual"
            :total-rows="tabelaTitulos.quantidadeItens"
            :per-page="tabelaTitulos.porPagina"
            hide-goto-end-buttons
            @change="mudarPaginaTitulos"
          ></b-pagination>
        </div> -->
      </div>

      <div class="mx-2 d-flex flex-column">
        <div class="flex-fill d-flex align-items-center">
          <b-button variant="valorem-dourado" pill @click="adicionarItems">
            <div class="d-flex">
              <feather type="arrow-right"></feather>
            </div>
          </b-button>
        </div>
        <div style="height: 65.5px"></div>
        <!-- Espacamento -->
      </div>

      <div
        class="d-flex flex-column"
        :class="focusNessaOperacao ? 'w-50' : 'w-25'"
      >
        <b-card
          class="flex-fill"
          @mouseover="focusNessaOperacao = false"
          @mouseleave="focusNessaOperacao = false"
        >
          <div class="d-flex flex-column">
            <div>
              <h4>
                {{ $t(`TITULOS.NESTA_OPERACAO`) }}
              </h4>
              <div class="desc-operacao">
                {{ $t(`TITULOS.RESUMO_PROGAMACAO`) }}
              </div>

              <div class="row">
                <div
                  class="col-6 px-0 pr-1 d-flex flex-column align-items-center justify-content-center"
                >
                  <b-badge variant="success" class="w-100"
                    >Qtd. titulos</b-badge
                  >
                  <h6 class="text-success">{{ titulosSelecionados.length }}</h6>
                </div>
                <div
                  class="col-6 px-0 pl-1 d-flex flex-column align-items-center justify-content-center"
                >
                  <b-badge variant="info" class="w-100">Valor</b-badge>
                  <h6 class="text-info">{{ valorTotalTitulos }}</h6>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-baseline justify-content-between">
              <input-date
                label="Data de programação"
                v-model="formProgramacao.dataPagamento"
                :disabled="bloquearEdicaoData"
              >
                <template #label>
                  <small> Data de programação </small>
                </template>
              </input-date>
              <div class="d-flex align-items-baseline">
                <b-dropdown
                  right
                  class="titulos-arquivos m-3"
                  variant="flat-secondary"
                  :disabled="formProgramacao.arquivos.length == 0"
                  v-b-tooltip.hover
                  title="Comprovantes anexados"
                >
                  <template #button-content>
                    <div>
                      <i class="fas fa-file-alt"></i>
                      <span class="ml-2" v-if="focusNessaOperacao"
                        >Arquivos</span
                      >
                      <b-badge
                        variant="danger"
                        v-if="formProgramacao.arquivos.length"
                        style="position: absolute; top: -5px; margin-left: 1px"
                        ><small>{{
                          formProgramacao.arquivos.length
                        }}</small></b-badge
                      >
                    </div>
                  </template>
                  <div style="width: 400px" class="px-2">
                    <b-list-group flush>
                      <b-list-group-item
                        class="p-1"
                        v-for="(arquivo, i) in formProgramacao.arquivos"
                        :key="`arquivo-${i}`"
                      >
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <span>{{ arquivo.nome }}</span>
                          <b-button
                            variant="flat-danger"
                            @click="removerArquivo(arquivo)"
                          >
                            <div class="d-flex">
                              <i class="fas fa-trash"></i>
                            </div>
                          </b-button>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-dropdown>
                <b-dropdown
                  right
                  class="titulos-arquivos"
                  variant="valorem-azul-naval"
                  ref="dropdown-upload-arquivos"
                  v-b-tooltip.hover
                  title="Anexar comprovantes"
                >
                  <template #button-content>
                    <div>
                      <i class="fas fa-paperclip"></i>
                      <span v-if="focusNessaOperacao" class="ml-2"
                        >Upload de arquivos</span
                      >
                    </div>
                  </template>
                  <div style="width: 400px" class="px-2">
                    <upload
                      id="prog-pagamento-upload"
                      toast-upload
                      @upload="
                        (file, nome, extensao) => {
                          formProgramacao.arquivos.push({
                            base64: file,
                            extensao: extensao,
                            nome: nome,
                          });
                          $refs['dropdown-upload-arquivos'].hide(true);
                        }
                      "
                      type=".*"
                    />
                  </div>
                </b-dropdown>
              </div>
            </div>
            <b-badge variant="danger" v-if="proximoDiaUtil">
              <small style="white-space: pre-wrap">
                A operação será programada para o dia
                <strong>{{ proximoDiaUtil }}</strong> devido ao dia útil
              </small>
            </b-badge>
            <div class="d-flex align-items-center">
              <hr class="flex-fill" />
              <span class="mx-2">Titulos</span>
              <hr class="flex-fill" />
            </div>
            <div style="overflow: auto; height: 313px">
              <accordion
                class="mb-1"
                :title="empresa.empresa"
                visible
                v-for="(empresa, i) in titulosAgrupados"
                :key="`accordion-empresa-${i}`"
                :accordion="`accordion-empresa-${i}`"
              >
                <template #append-title>
                  <div
                    class="w-100 d-flex align-items-center justify-content-end"
                  >
                    <b-badge variant="success" class="mx-2">
                      {{ empresa.titulos.length }}
                    </b-badge>
                    <b-badge variant="info">
                      {{ formatarValor(empresa.total) }}
                    </b-badge>
                  </div>
                </template>
                <valorem-tabela
                  :colunas="colunasTitulosProgramacao"
                  v-model="empresa.titulos"
                  sem-paginacao
                  class="tabela-titulos-programacao"
                >
                  <template #cell(acoes)="titulo">
                    <b-button
                      variant="flat-danger"
                      @click="removerTitulo(titulo)"
                    >
                      <div class="d-flex">
                        <i class="fas fa-trash"></i>
                      </div>
                    </b-button>
                  </template>
                  <template #cell(vencimento)="data">
                    <div class="d-flex align-items-center">
                      <span>{{ data.value }}</span>
                      <i
                        v-if="validarDataTitulo(data.unformatted)"
                        class="fas fa-exclamation-triangle ml-2 text-danger"
                        v-b-tooltip.hover
                        title="Data de programação é maior que a data de vencimento do titulo"
                      ></i>
                    </div>
                  </template>
                </valorem-tabela>
              </accordion>
            </div>
            <hr />
            <b-button
              @click="programarPagamento"
              variant="primary"
              :disabled="!podeProgramar"
            >
              {{ $t(`TITULOS.PROGAMAR_PAGAMENTO`) }}
            </b-button>
          </div>
        </b-card>
        <div style="height: 65.5px"></div>
        <!-- Espacamento -->
      </div>
    </div>
  </div>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import {
  InputCheckbox,
  InputDate,
  InputText,
  InputSelect,
  InputSelectTag,
} from "@/components/inputs";
import Upload from "../../components/upload-clear/Upload.vue";
import helpers from "@/common/utils/helpers";
import ProgramacaoService from "@/common/services/programacao/programacao.service";
import TituloService from "@/common/services/titulo/titulo.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import RotuloBoletosService from "@/common/services/rotulo-boletos/rotulo-boletos.service";
import Toast from "vue-toastification";
import Vue from "vue";
import Accordion from "@/components/card/Accordion";
import moment from "moment";

const options = {};

Vue.use(Toast, options);

export default {
  name: "Titulos",
  components: {
    InputCheckbox,
    InputDate,
    InputText,
    InputSelect,
    InputSelectTag,
    Accordion,
    Upload,
  },
  data() {
    return {
      proximoDiaUtil: "",
      bloquearEdicaoData: false,
      focusNessaOperacao: false,
      cedenteOptions: [],
      empresaOptions: [],
      sacadoOptions: [],
      rotulosOptions: [],
      pageOptions: [5, 10, 25, 50, 100],
      simNaoOptions: ["Sim", "Não"],
      form: {
        dataPagamento: null,
        DataVencimentoDe: null,
        DataVencimentoAte: null,
        C99: false,
        C99String: "Não",
        cpfCnpjCedente: "",
        empresaValue: "",
        sacadosValue: [],
        campoOrdenacao: "DataVencimento",
        ascendente: true,
        rotuloCodigo: null,
      },
      formProgramacao: {
        dataPagamento: null,
        arquivos: [],
      },
      tabelaTitulos: {
        quantidadeItens: 0,
        paginaAtual: 1,
        porPagina: 10,
        colunas: [
          {
            key: "arquivo",
            label: "",
            thClass: "item-table",
          },
          {
            key: "titulo",
            label: this.$t("SACADO.TITULO"),
            sortable: true,
          },
          {
            key: "nomeSacado",
            label: this.$t("SACADO.SACADO"),
            sortable: true,
            thClass: "fornecedor",
          },
          {
            key: "nomeFantasia",
            label: this.$t("SACADO.EMPRESA"),
            sortable: true,
          },
          {
            key: "dataVencimento",
            label: this.$t("SACADO.DATA_VENCIMENTO"),
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
            },
            sortable: true,
          },
          {
            key: "flagVencido",
            label: this.$t("SACADO.SITUACAO"),
            sortable: true,
          },
          {
            key: "valorAtualizado",
            label: this.$t("SACADO.VALOR_PARA_DEPOSITORIO"),
            sortable: true,
            formatter: (value) => {
              if (value) return helpers.formatarValor(value);
            },
          },
          {
            key: "rotuloNome",
            label: this.$t("SACADO.ROTULO"),
            sortable: true,
          },
        ],
      },
      items: [],
      QuantidadeTotalRetonadoQprof: 0,
      QuantidadeTotalQProf: 0,
      tempoAcesso: "",
      validField: [],
      titulosSelecionados: [],
      arquivosSelecionados: [],
      colunasTitulosProgramacao: [
        {
          key: "titulo",
          label: "Titulo",
        },
        {
          key: "vencimento",
          label: "Vencimento",
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        {
          key: "acoes",
          label: "",
        },
      ],
    };
  },
  mounted() {
    // this.listar();
    let data = moment();
    this.formProgramacao.dataPagamento = data.format("YYYY-MM-DD");

    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: "Programação" },
      { titulo: "Listar Programação" },
    ]);

    if (this.temFiltroCedente) {
      this.getUsuarios();
    }

    this.getRotulos();
  },
  watch: {
    titulosSelecionados: function () {
      this.bloquearEdicaoData = false;
      if (this.titulosSelecionados.some((el) => el.situacao == "Em Atraso")) {
        this.bloquearEdicaoData = true;
        this.formProgramacao.dataPagamento = moment().format("YYYY-MM-DD");
      }
    },
    "formProgramacao.dataPagamento": function () {
      let data = moment(this.formProgramacao.dataPagamento);
      let diaSemana = data.day();
      if (diaSemana != 6 && diaSemana != 0) {
        this.proximoDiaUtil = "";
        return;
      }

      let dataFinal = data.add(1, "days");
      if (diaSemana == 6) dataFinal = data.add(1, "days");

      this.proximoDiaUtil = dataFinal.format("DD/MM/YYYY");
    },
    "form.C99String": function () {
      this.form.C99 = false;
      if (this.form.C99String == "Sim") {
        this.form.C99 = true;
      }
    },
  },
  computed: {
    podeProgramar: function () {
      if (this.formProgramacao.dataPagamento == null) return false;

      for (let i = 0; i < this.titulosSelecionados.length; i++) {
        const element = this.titulosSelecionados[i];
        if (this.validarDataTitulo(element.vencimento)) return false;
      }

      return this.titulosSelecionados.length > 0;
    },
    titulosAgrupados() {
      let agrupado = this.titulosSelecionados.reduce((grupo, titulo) => {
        const { nomeEmpresa } = titulo;
        grupo[nomeEmpresa] = grupo[nomeEmpresa] ?? [];
        grupo[nomeEmpresa].push(titulo);
        return grupo;
      }, {});

      return Object.entries(agrupado).map((el) => {
        return {
          empresa: el[0],
          titulos: el[1],
          total: el[1].reduce((total, titulo) => {
            return total + titulo.valorAtualizado;
          }, 0),
        };
      });
    },
    todosSelecionados: {
      get: function () {
        if (this.items.length == 0) return false;
        return !this.items.filter((el) => !el.active).length;
      },
    },
    valorTotalAtualizado() {
      return helpers.formatarValor(
        this.items.reduce((total, titulo) => {
          return total + titulo.valorAtualizado;
        }, 0)
      );
    },
    valorTotal() {
      return helpers.formatarValor(
        this.items.reduce((total, titulo) => {
          return total + titulo.valor;
        }, 0)
      );
    },
    valorTotalTitulos() {
      return helpers.formatarValor(
        this.titulosSelecionados.reduce((total, titulo) => {
          return total + titulo.valorAtualizado;
        }, 0)
      );
    },
    valorTotalSelecionados() {
      return helpers.formatarValor(
        this.items
          .filter((el) => el.active)
          .reduce((total, titulo) => {
            return total + titulo.valorAtualizado;
          }, 0)
      );
    },
    temFiltroCedente() {
      return helpers.validarAcesso("FiltroTodosCedentesProgramacao").visualizar;
    },
    filtroCedenteIsEmpty() {
      return !!this.form?.cpfCnpjCedente;
    },
  },
  methods: {
    selecionarTodos() {
      let selecionado = this.todosSelecionados;
      this.items.forEach((element, key) => {
        this.selecionarTitulos(key, selecionado);
      });
    },
    // validarDataPagamento(data) {
    //   if (data?.length === 10) {
    //     const date = new Date(data);
    //     const today = new Date();
    //     date.setHours(0, 0, 0, 0);
    //     today.setHours(0, 0, 0, 0);
    //     return date >= today
    //   }
    //   return true
    // },
    sortingChanged(context) {
      this.items = [];
      this.form.campoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy);
      this.form.ascendente = !context.sortDesc;
      this.listarTitulos();
    },
    limparFiltro() {
      this.form = {
        C99: false,
        dataPagamento: null,
        cpfCnpjCedente: "",
        empresaValue: "",
        sacadosValue: [],
        campoOrdenacao: "DataVencimento",
        ascendente: true,
      };
      this.items = [];
      this.arquivosSelecionados = [];
      this.QuantidadeTotalRetonadoQprof = 0;
      this.QuantidadeTotalQProf = 0;
    },
    customLabel({ text }) {
      return text;
    },
    onChangeSacados(value) {
      this.form.sacadosValue = value;
    },
    onChangeEmpresa(value) {
      this.form.empresaValue = value;
    },
    onChangeCedente(value) {
      this.form.cpfCnpjCedente = value;
    },
    classObterBoleto() {
      return this.arquivosSelecionados.length > 0
        ? "botao-baixar"
        : "botao-baixar disabled";
    },
    obterBoletos() {
      this.$store.dispatch(START_LOADING);

      ProgramacaoService.obterBoletos({
        listaBoletosRequest: this.arquivosSelecionados,
        cnpjSacado: this.form.CpfCnpjSacado,
      })
        .then((result) => {
          let itens = result.data.data;

          this.downloadPDF(itens.arquivoBoleto, itens.arquivoNome);
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    downloadPDF(pdf, _fileName) {
      let type = this.arquivosSelecionados.length > 1 ? "zip" : "pdf";
      const linkSource = `data:application/${type};base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = _fileName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    validarFiltro() {
      let arValidation = [];

      if (this.temFiltroCedente && !this.filtroCedenteIsEmpty) {
        Vue.$toast.error("É necessário selecionar um cedente no filtro.");
        return false;
      }

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    mudarPaginaTitulos(pagina) {
      this.listarTitulos(pagina, undefined);
      this.tabelaTitulos.paginaAtual = pagina;
    },
    setPorPagina(e) {
      this.listarTitulos(undefined, e);
      this.tabelaTitulos.porPagina = e;
    },
    getUsuarios() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };
      UsuarioService.listar(params)
        .then((result) => {
          this.cedenteOptions = result.data.data.itens.map((n) => ({
            value: n.cnpj,
            text: n.nomeEmpresa,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getRotulos() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };

      RotuloBoletosService.listar(params)
        .then((result) => {
          this.rotulosOptions = result.data.data.itens.map((n) => ({
            value: n.codigo,
            text: n.nome,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    programarPagamento() {
      // if (!this.$refs.dataPagamento?.valid()) {
      //   return
      // }

      this.$store.dispatch(START_LOADING);
      let dataEnvio;

      dataEnvio = {
        titulos: this.titulosSelecionados,
        status: 0, //programado
        dataPagamento: this.proximoDiaUtil
          ? moment(this.proximoDiaUtil, "DD/MM/YYYY").format("YYYY-MM-DD")
          : this.formProgramacao.dataPagamento,
        arquivos: this.formProgramacao.arquivos,
        forceSuccess: true,
        cpfCnpjCedente: this.form?.cpfCnpjCedente,
      };

      ProgramacaoService.programarPagamento(dataEnvio)
        .then(() => {
          this.titulosSelecionados = [];
          this.formProgramacao.dataPagamento = null;
          this.formProgramacao.arquivos = [];
          this.listarTitulos();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    listarTitulos(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      const {
        cpfCnpjCedente,
        empresaValue,
        sacadosValue,
        rotuloCodigo,
        ...params
      } = this.form;

      if (empresaValue?.value) params.nomeFantasia = empresaValue?.value;
      if (cpfCnpjCedente) params.cpfCnpjCedente = cpfCnpjCedente;
      if (rotuloCodigo) params.rotuloCodigo = rotuloCodigo;

      if (sacadosValue?.length > 0) {
        params.sacados = sacadosValue;
      }

      TituloService.listarTitulos({
        ...params,
        Status: 1, // Trazer apenas titulos abertos
        RemoverProgramados: true, // Trazer apenas titulos não programados
        NumeroDaPagina: (numeroPagina || this.tabelaTitulos.paginaAtual) - 1,
        TamanhoDaPagina: porPagina || this.tabelaTitulos.porPagina,
      })
        .then((result) => {
          this.QuantidadeTotalRetonadoQprof =
            result.data.data.quantidadeTotalRetonadoQprof;
          this.QuantidadeTotalQProf = result.data.data.quantidadeTotalQProf;

          if (this.QuantidadeTotalQProf == 0) {
            Vue.$toast.warning("A consulta não retornou titulos!");
          }

          this.tabelaTitulos.quantidadeItens =
            result.data.data.paginacao.totalDeElementos;
          this.items = result.data.data.itens;

          this.sacadoOptions = result.data.data.sacados.map((n) => ({
            value: n,
            text: n,
          }));

          this.empresaOptions = result.data.data.empresas.map((n) => ({
            value: n,
            text: n,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    formatarValor(valor) {
      return helpers.formatarValor(valor);
    },
    classAdicionar() {
      return this.arquivosSelecionados.length > 0
        ? "btn-adicionar-icon"
        : "btn-adicionar-icon disabled-adicionar";
    },
    classProgramar() {
      // if (!this.$refs.dataPagamento?.valid()) {
      //   return "botão-programar disabled"
      // }
      return this.titulosSelecionados.length > 0 && this.form.dataPagamento
        ? "botão-programar"
        : "botão-programar disabled";
    },
    verificarDuplicado(item) {
      var index = this.titulosSelecionados.findIndex(
        (x) => x.numero == item.numero
      );
      if (index === -1) {
        this.titulosSelecionados.push(item);
      } else {
        //todo (verificar logica)
        console.log("object already exists");
      }
    },
    adicionarItems() {
      for (let i = 0; i < this.arquivosSelecionados.length; i++) {
        this.verificarDuplicado(this.arquivosSelecionados[i]);
      }
      let items = this.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].active) {
          items[i].active = false;
        }
      }
      this.items = items;
      this.arquivosSelecionados = [];
    },
    selecionarTitulos(index, selecionado = undefined) {
      let items = this.items;

      this.$set(
        this.items[index],
        "active",
        selecionado !== undefined ? !selecionado : !this.items[index].active
      );
      console.log(index);
      console.log(items);
      console.log(items[index]);

      items[index].active = this.items[index].active;
      this.arquivosSelecionados = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].active) {
          this.arquivosSelecionados.push({
            codigoEmpresa: items[i].codigoEmpresa,
            codigoFilial: items[i].codigoFilial,
            sequencialTitulo: items[i].sequencialTitulo,
            numero: items[i].titulo,
            titulo: items[i].titulo,
            valorAtualizado: items[i].valorAtualizado,
            vencimento: items[i].dataVencimento,
            nomeEmpresa: items[i].nomeFantasia,
            situacao: items[i].situacao,
          });
        }
      }

      //
    },
    removerTitulo(item) {
      this.titulosSelecionados.splice(
        this.titulosSelecionados.findIndex((el) => {
          return el === item;
        }),
        1
      );

      if (this.titulosSelecionados.length == 0) {
        let data = moment();
        this.formProgramacao.dataPagamento = data.format("YYYY-MM-DD");
      }
    },
    removerArquivo(item) {
      this.formProgramacao.arquivos.splice(
        this.formProgramacao.arquivos.findIndex((el) => {
          return el === item;
        }),
        1
      );
    },
    validarDataTitulo(data) {
      if (this.formProgramacao.dataPagamento == null) return false;

      if (this.bloquearEdicaoData) return false;

      let data1 = moment(data);
      let data2 = moment(this.formProgramacao.dataPagamento);

      let diaSemana = data1.day();

      if (diaSemana != 6 && diaSemana != 0) return data2.diff(data1) > 0;

      if (diaSemana == 0 && data2.diff(data1, "days") == 1) return false;

      if (diaSemana == 6 && data2.diff(data1, "days") == 2) return false;

      if (data2.diff(data1) == 0) return false;

      return data2.diff(data1) > 0;
    },
  },
};
</script>

<style>
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  font-size: 13px;
}

.table td,
table th,
.table td,
.table th {
  font-size: 12;
}

.botao-baixar {
  margin-bottom: 10px;
}

.linha {
  height: 100%;
  width: 1px;
  background: #e9ecef;
  margin: auto;
}

.div-adicionar {
  margin-left: 10px;
  margin-right: 10px;
  width: 85px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.card-tabela {
  min-height: 400px;
}

.btn-adicionar {
  margin: auto;
}

.container-tabela {
  display: flex;
  height: calc(100% - 50px);
}

.table {
  height: auto;
}

.btn-adicionar-icon {
  background: #9f8b6a;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  color: white;
  display: flex !important;
  margin: auto;
  transition: 0.5s;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.btn-adicionar-icon:hover {
  transform: scale(1.1);
}

.desc-operacao {
  color: #8b8b8b;
}

.titulos-valor {
  display: flex;
  justify-content: space-between;
}

.card-titulo {
  background: #f1f3f5;
  padding: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  width: 50%;
  margin: 3px;
}

.botao-filtro {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
}

.titulos-selecionados {
  padding: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin: 3px;
  height: 160px;
}

.div-operacoes {
  width: 250px;
}

.botão-programar {
  width: 100%;
}

.button-filter {
  margin: 3px;
  cursor: pointer;
}

.checkbox-sacado {
  display: grid;
  height: 95px;
  width: 30px;
  margin-top: 0px;
}

.documento-sacado {
  width: 115px;
}

.data-filtro {
  width: 75px;
}

.numero-titulo {
  width: 60px;
}

form {
  display: flex;
  align-items: center;
}

.valor-total {
  padding: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin: 3px;
  display: flex;
  width: 100%;
  justify-content: end;
}

.item-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.botao-remover {
  transition: 0.5s;
}

.botao-remover:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.container-titulos {
  height: 120px;
  overflow-y: auto;
  padding: 10px;
}

.container-titulos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f8f9fa;
  -webkit-box-shadow: inset 0 0 6px #f8f9fa;
  border-radius: 10px;
  background-color: #f8f9fa;
}

.container-titulos::-webkit-scrollbar {
  width: 8px;
  height: 13px;
  background-color: #f5f5f5;
}

.disabled-adicionar {
  opacity: 0.6;
  pointer-events: none;
}

.div-tabela {
  height: 523px;
  overflow: auto;
}

.container-titulos::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d8dbdd;
}

.container-titulos table thead th {
  white-space: nowrap;
  min-width: 170px;
}

.tabela-titulos-programacao table td,
.tabela-titulos-programacao table th {
  font-size: 12px;
  padding: 5px;
}

.titulos-arquivos .dropdown-toggle::after {
  display: none !important;
}
</style>

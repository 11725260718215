<template>
  <div class="icones-titulos">
    <b-dropdown
      right
      class="titulos-arquivos m-3"
      variant="flat-secondary"
      :disabled="registro.arquivos.length == 0"
      v-b-tooltip.hover title="Comprovantes anexados"
    >
      <template #button-content>
        <div>
          <i class="fas fa-file-alt"></i>
          <b-badge
            variant="danger"
            v-if="registro.arquivos.length"
            style="position: absolute; top: -5px; margin-left: 1px"
            ><small>{{ registro.arquivos.length }}</small></b-badge
          >
        </div>
      </template>
      <div style="width: 400px" class="px-2">
        <b-list-group flush>
          <b-list-group-item
            class="p-1"
            v-for="(arquivo, i) in registro.arquivos"
            :key="`arquivo-${i}`"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span>{{ arquivo.nome }}</span>
              <div style="display: flex; justify-content: end">
                <b-button variant="flat-info" @click="baixarArquivo(arquivo)">
                  <div class="d-flex">
                    <i class="fas fa-cloud-download-alt"></i>
                  </div>
                </b-button>
                <b-button
                  variant="flat-danger"
                  @click="removerArquivo(arquivo, registro.id)"
                >
                  <div class="d-flex">
                    <i class="fas fa-trash"></i>
                  </div>
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-dropdown>
    <b-dropdown v-b-tooltip.hover title="Anexar comprovantes" right class="titulos-arquivos" variant="valorem-azul-naval">
      <template #button-content>
        <div>
          <i class="fas fa-paperclip"></i>
        </div>
      </template>
      <div style="width: 400px" class="px-2">
        <upload
          :id="`prog-pagamento-upload-${registro.id}`"
          toast-upload-sem-notificacao
          @upload="
            (file, nome, extensao) => {
              uploadAnexo(registro, file, nome, extensao);
            }
          "
          type=".*"
        />
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import ProgramacaoService from "@/common/services/programacao/programacao.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Upload from "@/components/upload-clear/Upload.vue";
export default {
  props: {
    registro: {},
  },
  components : {
    Upload
  },
  methods: {
    uploadAnexo: function (item, file, nome, extensao) {
      let arquivo = {
        base64: file,
        extensao: extensao,
        nome: nome,
      };
      this.confirmar("Confirmar", "Deseja realizar upload do arquivo?").then(
        (confirmado) => {
          if (!confirmado) return;
          this.$store.dispatch(START_LOADING);
          ProgramacaoService.AnexarArquivo({
            id: item.id,
            arquivos: [arquivo],
          })
            .then(() => {
              this.toastSucesso("Arquivo adicionado com sucesso!");
              this.$emit("atualizar");
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      );
    },
    baixarArquivo: function (arquivo) {
      var a = document.createElement("a");
      a.href = `${arquivo.arquivoBase64}`;
      a.download = arquivo.nome;
      a.click();
    },
    removerArquivo: function (arquivo, id) {
      let form = {
        arquivoAnexoId: arquivo.id,
        id: id,
      };
      this.confirmar("Deseja remover comprovante?").then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        ProgramacaoService.removerArquivoAnexo(form)
          .then(() => {
            this.toastSucesso("Arquivo removido com sucesso!");
            this.$emit("atualizar");
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
};
</script>
<style>
.titulos-arquivos .dropdown-toggle::after {
  display: none !important;
}
</style>
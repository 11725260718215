<template>
  <div class="input-dragdrop" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <div class="row">
      <div class="col-12 mt-2 d-flex justify-content-center">
        <img  :src="upIcon" class="mr-2"/>
        <div class="uploat-text-content">
          <strong>{{ tituloArquivo || "Arrastar arquivo" }}</strong>
          <div class="upload-desc">Formatos ({{ type || ".jpg, .jpeg, .png, .svg" }})</div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center mt-2" :class="!nomeArquivo ? 'mb-2' : ''" >
        <b-button @click="fileUp">
          {{ selecioneArquivo || "Selecionar arquivo" }}
        </b-button>
      </div>

    </div>
    <hr class="mx-3" v-if="nomeArquivo">
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center mb-2" v-if="nomeArquivo" >
        <img v-if="typeImage" class="img-upload" :src="logo" />
        <div v-else-if="nomeArquivo" class="file-upload uploat-text-content">
          <i class="fas fa-file mr-2"></i>
          <strong>{{ nomeArquivo }}</strong>
        </div>
        <b-button @click="remover" variant="flat-danger" class="ml-2" size="sm">
          <i class="fas fa-trash"></i>
        </b-button>
        <b-button @click="download" variant="flat-info" v-if="arquivoDownload">          
          <i class="fas fa-cloud-download"></i>          
        </b-button>
      </div>
      
    </div>
    <input :accept="type || '.jpg, .jpeg, .png, .svg'" type="file" :id="id" @change="inputUpload" class="fileup-input" name="" />
  </div>
</template>

<script>
import helpers from "@/common/utils/helpers";
export default {
  name: "Upload",
  props: {
    type: { type: String },
    tituloArquivo: { type: String },
    selecioneArquivo: { type: String },
    logoDaEmpresa: { type: String },
    id: { type: String,
      default: "fileup-input"
    },
    arquivoDownload: { type: String },
    arquivoDownloadNome: { type: String },

    typeImage: { type: Boolean, default: false },
    toastUpload : { type: Boolean, default: false },
    toastUploadSemNotificacao : { type: Boolean, default: false },
  },
  data() {
    return {
      upIcon: require("./upload.svg"),
      file: this.logoDaEmpresa,
      logo: this.logoDaEmpresa,
      nomeArquivo: undefined
    };
  },
  methods: {
    download(){
      helpers.baixarBase64(this.arquivoDownload, this.arquivoDownloadNome);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    inputUpload(event) {
      this.drop(event);
      event.target.value = "";
    },
    fileUp() {
      document.getElementById(this.id).click();
    },
    drop(event) {

      event.preventDefault();

      let files = event.target.files || event.dataTransfer.files;
      
      this.nomeArquivo = files[0].name;
      let extensao = this.nomeArquivo.split(".").pop();
 
      this.getBase64(files[0]).then((data) => {
        
        this.logo = data;
        this.$emit("upload", data, this.nomeArquivo, extensao );

        if(this.toastUploadSemNotificacao)
          this.nomeArquivo = "";

        if(this.toastUpload){
          this.nomeArquivo = "";
          this.toastSucesso("Arquivo adicionado com sucesso!")
        }
      });
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
    },

    remover(){
      this.logo = null;
      this.nomeArquivo = null;
      this.$emit("upload", null, this.nomeArquivo );
    }
  },
  watch: {
    arquivoDownloadNome : {
      handler(valor){
        if(valor){
          this.nomeArquivo = valor;
        }
      },
      immediate: true
    }
  }
};
</script>
<style>
  .input-dragdrop{
    border: 1px dashed ;
    border: 1px dashed #ced4da;
    background: #f8f9fa;
  }
  
</style>

import ApiService from "@/common/api/api.service";
import helpers from "../../utils/helpers";

const ProgramacaoService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj) {
      if (Array.isArray(obj[p])) {
        obj[p].forEach((item) => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(item));
        });
        continue;
      }
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }

    return str.join('&');
  },

  listarTitulos(form) {
    form.CpfCnpjSacado = form.CpfCnpjSacado
      ? helpers.removerMascara(form.CpfCnpjSacado)
      : "";
    // form.CpfCnpjCedente = form.CpfCnpjCedente ? helpers.removerMascara(form.CpfCnpjCedente) : '';
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/ProgramacaoPagamento/titulos?${sendForm}`);
  },

  programarPagamento(form) {
    form.CpfCnpjSacado = form.CpfCnpjSacado
      ? helpers.removerMascara(form.CpfCnpjSacado)
      : "";

    return ApiService.post(`/ProgramacaoPagamento`, form);
  },
  atualizarStatus(form) {
    return ApiService.put(`/ProgramacaoPagamento`, form);
  },
  buscarProgramacao(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/ProgramacaoPagamento?${sendForm}`);
  },
  buscarBanco(form) {
    return ApiService.get(
      `/ContaBancaria/${form.codigoEmpresa}/${form.codigoFilial}`
    );
  },
  removerProgramacao(id) {
    return ApiService.delete(`/ProgramacaoPagamento/${id}`);
  },
  baixarExcel(id) {
    return ApiService.get(`/ProgramacaoPagamento/html-excel/${id}`);
  },
  baixarPDF(id) {
    return ApiService.downloadFile(`/ProgramacaoPagamento/pdf/${id}`)
  },
  AnexarArquivo(form) {
    return ApiService.put(`/ProgramacaoPagamento/anexar-arquivos`, form);
  },
  removerArquivoAnexo(form) {
    return ApiService.put(`/ProgramacaoPagamento/remover-anexo`, form);
  },
  baixarPDFTitulo(id) {
    return ApiService.get(`/ProgramacaoPagamento/pdf/${id}`)
  },
};

export default ProgramacaoService;

<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-3 tab-autenticacao"
    @input="onChange"
  >
    <b-tab
      class="tabs-autenticacao"
      :title-link-class="linkClass(0)"
      title="Títulos"
    >
      <Titulos ref="tituloRef" />
    </b-tab>
    <b-tab
      class="tabs-autenticacao"
      :title-link-class="linkClass(1)"
      title="Programações"
    >
      <TitulosProgramado ref="tituloProgramadoRef" />
    </b-tab>
  </b-tabs>
</template>

<script>
import Titulos from './Titulos.vue';
import TitulosProgramado from './TitulosProgramado.vue';
export default {
  name: 'TitulosTabs',
  data() {
    return {
      tabIndex: window.location.pathname == '/programacao' ? 0 : 1,
    };
  },
  components: {
    Titulos,
    TitulosProgramado,
  },
  mounted() {},
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['tabs-autenticacao', 'active-tab'];
      } else {
        return ['tabs-autenticacao'];
      }
    },
    onChange(idx) {
      if (idx === 0) {
        this.$refs.tituloRef?.listarTitulos();
      } else {
        this.$refs.tituloProgramadoRef?.listarPagamentos();
      }
    },
  },
};
</script>
<style lang="scss">
.nav-link {
  padding: 1rem 1.5rem;
}
.tab-autenticacao {
  min-height: 500px;
}
.active-tab {
  background-color: #a08c6a !important;
  color: white !important;
}
</style>

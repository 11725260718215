<template>
    <b-card class="accordion" no-body>
        <div class="card__title py-1 d-flex justify-content-left align-items-center" v-b-toggle="`${disabled ? 'dont' : id}`">
            <i v-if="sub" class="sub fa-solid fa-arrow-turn-up mr-3"></i>
            <span class="ml-2 title d-flex align-items-center  w-100 pr-2">
                <slot name="prepend-title"/>
                <span>{{title}}</span>
                <slot name="append-title"/>
            </span>
        </div>
        <b-collapse :id="id" :accordion="accordion" :visible="visible">
            <slot></slot>
        </b-collapse>
    </b-card>
</template>
<script>
export default {
    props: {
        id: {
            type : String,
            default : () =>  {return `accordion-${Math.random()}`; },
        },
        title : {
            type: String,
            required: true
        },
        accordion: {
            type: String,
            default: 'accordion'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        sub: {
            type: Boolean,
            default: false
        },
    },
    data : () => ({
        aberto: false
    }),
    watch: {
        visible : {
            handler(valor){
                this.aberto = valor;
            },
            immediate: true
        }
    },
    mounted(){
        this.$root.$on(
            'bv::collapse::state',
            (id, collapsed) => {
                if(id == this.id){
                    if(collapsed)
                        this.$emit('abriu', this.id);                   
                }
            }
        );
    }
}
</script>
<style>
    .accordion{
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 4px;
    }

    .card__title{
        background: #f1f1f1;
    }

    .title{
        padding-left: 20px;
    }

    .title::before{
        content: "\f067";
        font-family: 'Font Awesome 5 Free' !important;
        font-weight: 600;
        -webkit-transition: -webkit-transform .25s;
        transition: -webkit-transform .25s;
        transition: transform .25s;
        transition: transform .25s, -webkit-transform .25s;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        color: #44a2d2;
        position: absolute;
        left: 10px;
    }

    .collapsed > .title::before{
        content: "\f067";
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    .not-collapsed > .title::before{
        content: "\f068";
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

</style>